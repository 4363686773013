import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import ImageGallery from '../components/ImageGallery';

export function Insulation() {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Typography variant="h2" align="center" component="h1" gutterBottom>
          Hőszigetelés
        </Typography>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="/assets/images/szigeteles/IMG_1496.jpg"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Container>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Hőszigetelés:
                  </Typography>

                  <ol>
                    <li>
                      <Typography variant="body1">
                        <strong>Felülvizsgálat:</strong> Az épület
                        hőveszteségének és szigetelési szükségleteinek
                        felülvizsgálata.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Megfelelő Anyagok Kiválasztása:</strong> A
                        megfelelő hőszigetelő anyagok kiválasztása az adott
                        épülettípus és az éghajlati viszonyok alapján.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Szigetelő Rétegek Felhordása:</strong> A
                        kiválasztott szigetelő anyagok felhordása a megfelelő
                        rétegekben a falakra, a padlóra és a tetőre.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Épületi Illesztések Szigetelése:</strong> Az
                        épületi illesztéseknél, ablakoknál és ajtóknál a
                        hőszigetelő anyagok pontos illesztése és szigetelése.
                      </Typography>
                    </li>
                  </ol>
                </Box>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Hőszigetelés Előnyei:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Energiahatékonyság:</strong> A ház hőszigetelése
                        csökkenti az energiaveszteséget, így csökkenti a fűtési
                        és hűtési költségeket.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Kényelmes Lakókörnyezet:</strong> A megfelelő
                        hőszigetelés hozzájárul a kényelmes hőmérséklet
                        fenntartásához a lakóterekben.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Hangszigetelés:</strong> A hőszigetelés segíthet
                        a zajszint csökkentésében külső és belső zajok ellen.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Élettartam Növelése:</strong> A megfelelő
                        hőszigetelés hozzájárul az épület szerkezeti
                        integritásának megőrzéséhez.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ py: 4 }}>
        <ImageGallery
          images={[
            'assets/images/szigeteles/IMG_0389.webp',
            'assets/images/szigeteles/IMG_0060.webp',
            'assets/images/szigeteles/IMG_0069.webp',
            'assets/images/szigeteles/IMG_0098.webp',
            'assets/images/szigeteles/IMG_0107.webp',
            'assets/images/szigeteles/IMG_0120.webp',
            'assets/images/szigeteles/IMG_0131.webp',
            'assets/images/szigeteles/IMG_0190.webp',
            'assets/images/szigeteles/IMG_0194.webp',
            'assets/images/szigeteles/IMG_0261.webp',
            'assets/images/szigeteles/IMG_0316.webp',
            'assets/images/szigeteles/IMG_0317.webp',
            'assets/images/szigeteles/IMG_0336.webp',
            'assets/images/szigeteles/IMG_0376.webp',
            'assets/images/szigeteles/IMG_0694.webp',
            'assets/images/szigeteles/IMG_0697.webp',
            'assets/images/szigeteles/IMG_1024.webp',
            'assets/images/szigeteles/IMG_1026.webp',
            'assets/images/szigeteles/IMG_1043.webp',
            'assets/images/szigeteles/IMG_1112.webp',
            'assets/images/szigeteles/IMG_1040.webp',
            'assets/images/szigeteles/IMG_2716.HEIC',
            'assets/images/szigeteles/IMG_2727.HEIC',
            'assets/images/szigeteles/IMG_0758.webp',
            'assets/images/szigeteles/IMG_0764.webp',
            'assets/images/szigeteles/IMG_0766.webp',
            'assets/images/szigeteles/IMG_0768.webp',
            'assets/images/szigeteles/IMG_0769.webp',
            'assets/images/szigeteles/IMG_1492.jpg',
            'assets/images/szigeteles/IMG_1496.jpg',
            'assets/images/szigeteles/IMG_1498.jpg',
            'assets/images/szigeteles/IMG_1627.jpg',
            'assets/images/szigeteles/IMG_1634.jpg',
            'assets/images/szigeteles/IMG_1636.jpg',
            'assets/images/szigeteles/IMG_1639.jpg',
            'assets/images/szigeteles/IMG_1643.jpg',
            'assets/images/szigeteles/IMG_1365.webp',
            'assets/images/szigeteles/IMG_1366.webp',
            'assets/images/szigeteles/IMG_2146.webp',
            'assets/images/szigeteles/IMG_2150.webp',
            'assets/images/szigeteles/IMG_2178.webp',
            'assets/images/szigeteles/IMG_2231.webp',
            'assets/images/szigeteles/IMG_2240.webp',
            'assets/images/szigeteles/IMG_2310.webp',
            'assets/images/szigeteles/IMG_2313.webp',
            'assets/images/szigeteles/IMG_2315.webp',
            'assets/images/szigeteles/IMG_4175.webp',
            'assets/images/szigeteles/IMG_3431.webp',
            'assets/images/szigeteles/IMG_3441.webp',
            'assets/images/szigeteles/IMG_3446.webp',
            'assets/images/szigeteles/IMG_3449.webp',
            'assets/images/szigeteles/IMG_3485.webp',
            'assets/images/szigeteles/IMG_3713.webp',
            'assets/images/szigeteles/IMG_4085.webp',
            'assets/images/szigeteles/IMG_4086.webp',
            'assets/images/szigeteles/IMG_4109.webp',
            'assets/images/szigeteles/IMG_4122.webp',
            'assets/images/szigeteles/IMG_4125.webp',
            'assets/images/szigeteles/IMG_1960.jpg',
            'assets/images/szigeteles/IMG_2014.jpg',
            'assets/images/szigeteles/IMG_1950.jpg',
            'assets/images/szigeteles/IMG_1951.jpg',
            'assets/images/szigeteles/IMG_2029.jpg',
            'assets/images/szigeteles/IMG_2092.jpg',
            'assets/images/szigeteles/IMG_2095.jpg',
            'assets/images/szigeteles/IMG_2024.jpg',
            'assets/images/szigeteles/IMG_2107.jpg',
            'assets/images/szigeteles/IMG_2112.jpg',
            'assets/images/szigeteles/IMG_2114.jpg',
            'assets/images/szigeteles/IMG_4233.JPG',
            'assets/images/szigeteles/IMG_4235.JPG',
            'assets/images/szigeteles/IMG_4254.JPG',
            'assets/images/szigeteles/IMG_4274.JPG',
            'assets/images/szigeteles/IMG_4319.JPG',
            'assets/images/szigeteles/IMG_4396.JPG',
            'assets/images/szigeteles/IMG_4410.JPG',
            'assets/images/szigeteles/IMG_4419.JPG',
            'assets/images/szigeteles/IMG_4435.JPG',
            'assets/images/szigeteles/IMG_4493.JPG',
            'assets/images/szigeteles/IMG_3921.JPG',
            'assets/images/szigeteles/IMG_3939.JPG',
            'assets/images/szigeteles/IMG_3961.JPG',
            'assets/images/szigeteles/IMG_3965.JPG',
            'assets/images/szigeteles/IMG_3976.JPG',
            'assets/images/szigeteles/IMG_3981.JPG',
            'assets/images/szigeteles/IMG_3986.JPG',
            'assets/images/szigeteles/IMG_3990.JPG',
            'assets/images/szigeteles/IMG_3991.JPG',
            'assets/images/szigeteles/IMG_3998.JPG',
            'assets/images/szigeteles/IMG_2892.HEIC',
            'assets/images/szigeteles/IMG_2894.HEIC',
            'assets/images/szigeteles/IMG_2900.HEIC',
            'assets/images/szigeteles/IMG_2951.HEIC',
            'assets/images/szigeteles/IMG_2952.HEIC',
            'assets/images/szigeteles/IMG_2957.HEIC',
            'assets/images/szigeteles/IMG_2962.HEIC',
            'assets/images/szigeteles/IMG_2963.HEIC',
            'assets/images/szigeteles/IMG_2985.HEIC',
            'assets/images/szigeteles/IMG_20240822_134259.jpg',
            'assets/images/szigeteles/IMG_20240822_134310.jpg',
            'assets/images/szigeteles/IMG_20240822_134312.jpg',
            'assets/images/szigeteles/IMG_20241003_090449.jpg',
            'assets/images/szigeteles/IMG_20241003_090603.jpg',
            'assets/images/szigeteles/IMG_20241003_090647.jpg',
            'assets/images/szigeteles/IMG_20241003_090741.jpg',
            'assets/images/szigeteles/IMG_20241003_091325.jpg',
            'assets/images/szigeteles/IMG_20241003_091312.jpg',
            'assets/images/szigeteles/IMG_20241003_090319.jpg',
            'assets/images/szigeteles/IMG_20241003_090449.jpg',
            'assets/images/szigeteles/IMG_20241003_090459.jpg',
            'assets/images/szigeteles/IMG_2772.HEIC',
            'assets/images/szigeteles/IMG_2773.HEIC',
            'assets/images/szigeteles/IMG_2978.HEIC',
            'assets/images/szigeteles/IMG_20241212_100714.jpg',
            'assets/images/szigeteles/IMG_20241212_100856.jpg',
            'assets/images/szigeteles/IMG_20241212_100731.jpg',
          ]}
        />
      </Container>
    </>
  );
}
